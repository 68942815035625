import React from 'react';

// Next
import type { AppProps } from 'next/app';

// Styles
import '../styles/globals.css';
import '../styles/expander.css';
import '../styles/modal.css';

// AntD
import 'antd/dist/antd.css';

// React Intl
import { IntlProvider } from 'react-intl';

// Components
import '@phpcreation/frontend-components-react/build.css';
import { AppContextProvider as AppContext } from '@phpcreation/frontend-components-crud-react';
import { AppContextProvider } from '@phpcreation/frontend-components-react';

// Utils
import { setApiEndpoints } from '../utils/constants/constants';
import en from '../utils/locales/en/common.json';
import fr from '../utils/locales/fr/common.json';
import bi from '../utils/locales/bi/common.json';

const messages = {
  en,
  fr,
  bi,
};

const API_ENDPOINTS = {
  crud: process.env.NEXT_PUBLIC_CONFIG_CRUD ?? '',
  auth: process.env.NEXT_PUBLIC_CONFIG_AUTH ?? '',
  status: process.env.NEXT_PUBLIC_CONFIG_STATUS ?? '',
  documentation: process.env.NEXT_PUBLIC_CONFIG_DOC ?? '',
};

setApiEndpoints(API_ENDPOINTS);

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <IntlProvider locale="en">
      <AppContextProvider endpoints={API_ENDPOINTS} extraMessages={messages}>
        <AppContext endpoints={API_ENDPOINTS} extraMessages={messages}>
          <Component {...pageProps} />
        </AppContext>
      </AppContextProvider>
    </IntlProvider>
  );
}

export default MyApp;
