import { Endpoints } from '@phpcreation/frontend-components-crud-react';

//API
export const API_ENDPOINTS: Endpoints = {
  crud: 'https://dev-api.ticket.solidservice.link/api/v1',
  auth: 'https://dev-api.ticket.solidservice.link/api/login_check',
  status: 'https://dev-api.ticket.solidservice.link/status',
  documentation: 'https://dev.ticket.solidservice.link/api/v1',
};

export const setApiEndpoints = (endpoints: Endpoints) => {
  API_ENDPOINTS.crud =
    process.env.NEXT_PUBLIC_CONFIG_CRUD ??
    'https://dev-api.ticket.solidservice.link/api/v1';
  API_ENDPOINTS.auth =
    process.env.NEXT_PUBLIC_CONFIG_AUTH ??
    'https://dev-api.ticket.solidservice.link/api/login_check';
  API_ENDPOINTS.status =
    process.env.NEXT_PUBLIC_CONFIG_STATUS ??
    'https://dev-api.ticket.solidservice.link/status';
  API_ENDPOINTS.documentation =
    process.env.NEXT_PUBLIC_CONFIG_DOC ??
    'https://dev.ticket.solidservice.link/api/v1';
};
